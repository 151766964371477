@import url("./assets/fonts/TTCommons/stylesheet.css");

body {
  margin: 0;
  font-family: "TT Commons Regular";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  scroll-behavior: smooth;
}

.react-pdf__Page__canvas {
  margin: 0 auto;
  width: 80% !important;
  height: 100% !important;
}

.react-pdf__Page__textContent {
  display: none;
}
