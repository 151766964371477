@font-face {
    font-family: 'TT Commons';
    src: url('TTCommons-ThinItalic.eot');
    src: local('TT Commons Thin Italic'), local('TTCommons-ThinItalic'),
        url('TTCommons-ThinItalic.eot?#iefix') format('embedded-opentype'),
        url('TTCommons-ThinItalic.woff') format('woff'),
        url('TTCommons-ThinItalic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'TT Commons';
    src: url('TTCommons-Light.eot');
    src: local('TT Commons Light'), local('TTCommons-Light'),
        url('TTCommons-Light.eot?#iefix') format('embedded-opentype'),
        url('TTCommons-Light.woff') format('woff'),
        url('TTCommons-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'TT Commons';
    src: url('TTCommons-Black.eot');
    src: local('TT Commons Black'), local('TTCommons-Black'),
        url('TTCommons-Black.eot?#iefix') format('embedded-opentype'),
        url('TTCommons-Black.woff') format('woff'),
        url('TTCommons-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'TT Commons';
    src: url('TTCommons-Medium.eot');
    src: local('TT Commons Medium'), local('TTCommons-Medium'),
        url('TTCommons-Medium.eot?#iefix') format('embedded-opentype'),
        url('TTCommons-Medium.woff') format('woff'),
        url('TTCommons-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'TT Commons';
    src: url('TTCommons-Italic.eot');
    src: local('TT Commons Italic'), local('TTCommons-Italic'),
        url('TTCommons-Italic.eot?#iefix') format('embedded-opentype'),
        url('TTCommons-Italic.woff') format('woff'),
        url('TTCommons-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'TT Commons';
    src: url('TTCommons-Regular.eot');
    src: local('TT Commons Regular'), local('TTCommons-Regular'),
        url('TTCommons-Regular.eot?#iefix') format('embedded-opentype'),
        url('TTCommons-Regular.woff') format('woff'),
        url('TTCommons-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'TT Commons';
    src: url('TTCommons-BlackItalic.eot');
    src: local('TT Commons Black Italic'), local('TTCommons-BlackItalic'),
        url('TTCommons-BlackItalic.eot?#iefix') format('embedded-opentype'),
        url('TTCommons-BlackItalic.woff') format('woff'),
        url('TTCommons-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'TT Commons';
    src: url('TTCommons-ExtraBold.eot');
    src: local('TT Commons ExtraBold'), local('TTCommons-ExtraBold'),
        url('TTCommons-ExtraBold.eot?#iefix') format('embedded-opentype'),
        url('TTCommons-ExtraBold.woff') format('woff'),
        url('TTCommons-ExtraBold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'TT Commons';
    src: url('TTCommons-BoldItalic.eot');
    src: local('TT Commons Bold Italic'), local('TTCommons-BoldItalic'),
        url('TTCommons-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('TTCommons-BoldItalic.woff') format('woff'),
        url('TTCommons-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'TT Commons';
    src: url('TTCommons-DemiBoldItalic.eot');
    src: local('TT Commons DemiBold Italic'), local('TTCommons-DemiBoldItalic'),
        url('TTCommons-DemiBoldItalic.eot?#iefix') format('embedded-opentype'),
        url('TTCommons-DemiBoldItalic.woff') format('woff'),
        url('TTCommons-DemiBoldItalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'TT Commons';
    src: url('TTCommons-Bold.eot');
    src: local('TT Commons Bold'), local('TTCommons-Bold'),
        url('TTCommons-Bold.eot?#iefix') format('embedded-opentype'),
        url('TTCommons-Bold.woff') format('woff'),
        url('TTCommons-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'TT Commons';
    src: url('TTCommons-ExtraLight.eot');
    src: local('TT Commons ExtraLight'), local('TTCommons-ExtraLight'),
        url('TTCommons-ExtraLight.eot?#iefix') format('embedded-opentype'),
        url('TTCommons-ExtraLight.woff') format('woff'),
        url('TTCommons-ExtraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'TT Commons';
    src: url('TTCommons-DemiBold.eot');
    src: local('TT Commons DemiBold'), local('TTCommons-DemiBold'),
        url('TTCommons-DemiBold.eot?#iefix') format('embedded-opentype'),
        url('TTCommons-DemiBold.woff') format('woff'),
        url('TTCommons-DemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'TT Commons';
    src: url('TTCommons-ExtraLightItalic.eot');
    src: local('TT Commons ExtraLight Italic'), local('TTCommons-ExtraLightItalic'),
        url('TTCommons-ExtraLightItalic.eot?#iefix') format('embedded-opentype'),
        url('TTCommons-ExtraLightItalic.woff') format('woff'),
        url('TTCommons-ExtraLightItalic.ttf') format('truetype');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: 'TT Commons';
    src: url('TTCommons-ExtraBoldItalic.eot');
    src: local('TT Commons ExtraBold Italic'), local('TTCommons-ExtraBoldItalic'),
        url('TTCommons-ExtraBoldItalic.eot?#iefix') format('embedded-opentype'),
        url('TTCommons-ExtraBoldItalic.woff') format('woff'),
        url('TTCommons-ExtraBoldItalic.ttf') format('truetype');
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: 'TT Commons';
    src: url('TTCommons-MediumItalic.eot');
    src: local('TT Commons Medium Italic'), local('TTCommons-MediumItalic'),
        url('TTCommons-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('TTCommons-MediumItalic.woff') format('woff'),
        url('TTCommons-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'TT Commons';
    src: url('TTCommons-LightItalic.eot');
    src: local('TT Commons Light Italic'), local('TTCommons-LightItalic'),
        url('TTCommons-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('TTCommons-LightItalic.woff') format('woff'),
        url('TTCommons-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'TT Commons';
    src: url('TTCommons-Thin.eot');
    src: local('TT Commons Thin'), local('TTCommons-Thin'),
        url('TTCommons-Thin.eot?#iefix') format('embedded-opentype'),
        url('TTCommons-Thin.woff') format('woff'),
        url('TTCommons-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}

